import httpClient from "@/api/httpClient";

const LIST_FORUM = "/api/forums";
const LIST_CATEGORY_FORUM = "/api/forums/categories";
const COMMENT = "/comments"
const PARTICIPANT = "/participant"
const USERS = "/api/users"

const getListForum = (_params) => httpClient.get(LIST_FORUM,{
    params: _params
})
const getListCategoryForum = (_params) => httpClient.get(LIST_CATEGORY_FORUM,{
    params: _params
})
const getListComment = (_params, _id) => httpClient.get(LIST_FORUM + "/" + _id + COMMENT,{
    params: _params
});
const getListParticipant = (_id) => httpClient.get(LIST_FORUM + "/" + _id + COMMENT + PARTICIPANT);
const giveComment = (payload, _id) =>
    httpClient.post(LIST_FORUM + "/" + _id + COMMENT, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const getDetailCategoryForum = (_id) => httpClient.get(LIST_CATEGORY_FORUM + "/" + _id, {})
const createCategoryForum = payload =>
    httpClient.post(LIST_CATEGORY_FORUM, payload, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
const updateCategoryForum = (payload, id) =>
    httpClient.post(LIST_CATEGORY_FORUM + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const deleteCategoryForum = (_id) => httpClient.delete(LIST_CATEGORY_FORUM +"/"+ _id);
const createForum = (payload) =>
    httpClient.post(LIST_FORUM, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const updateForum = (payload, id) =>
    httpClient.post(LIST_FORUM + "/" + id, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*",
        }
    });
const pinTopic = (_id) => httpClient.post(LIST_FORUM + "/" + _id + "/pin", {});
const unpinTopic = (_id) => httpClient.post(LIST_FORUM + "/" + _id + "/unpinned", {});
const getDetailForum = (_id) => httpClient.get(LIST_FORUM + "/" + _id, {});
const deleteForum = (_id) => httpClient.delete(LIST_FORUM +"/"+ _id);
const toggleForum = (id_forum) =>
    httpClient.post(LIST_FORUM + "/" + id_forum + "/toggle-comment");
const updateSettings = (_id, payload) => httpClient.post(LIST_FORUM + "/" + _id + "/settings", payload);
const getUsers = (params) => httpClient.get(USERS, { params });
export {
    getListForum,
    getListCategoryForum,
    getListParticipant,
    createCategoryForum,
    getDetailCategoryForum,
    updateCategoryForum,
    deleteCategoryForum,
    createForum,
    getDetailForum,
    deleteForum,
    getListComment,
    giveComment,
    pinTopic,
    unpinTopic,
    updateForum,
    toggleForum,
    updateSettings,
    getUsers
}
