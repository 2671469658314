<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Kategori Topik Baru</span>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-form :form="form" @submit="handleSubmit">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <a-form-item label="Kategori" class="col-md-12">
                      <a-input
                          placeholder="Nama kategori"
                          v-decorator="[
                          'name',
                          {
                            rules: [
                              {
                                required: true,
                                message: 'Field ini tidak boleh kosong'
                              },
                              {
                                min: 3,
                                message: 'Nama kategori minimal 3 karakter!'
                              },
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="Deskripsi" class="col-md-12">
                      <a-textarea
                          placeholder="Deskripsi"
                          v-decorator="[
                          'description',
                          {
                            rules: [
                              {
                                min: 3,
                                message: 'Deskripsi minimal 3 karakter!'
                              },
                            ]
                          }
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="Upload Foto" class="col-md-6">
                      <a-upload
                          name="icon"
                          listType="picture-card"
                          class="avatar-uploader"
                          :showUploadList="false"
                          :beforeUpload="beforeUpload"
                          @change="onFileChange"
                      >
                        <img
                            v-if="imageUrl"
                            :src="imageUrl"
                            alt="image"
                            class="img-fluid"
                        />
                        <div v-else>
                          <a-icon :type="loading ? 'loading' : 'plus'"/>
                          <div class="ant-upload-text">Pilih Foto</div>
                        </div>
                      </a-upload>
                    </a-form-item>
                  </div>
                </div>
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary btn-with-addon">
                    <span class="btn-addon">
                      <i class="btn-addon-icon fe fe-send"/>
                    </span>
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import {createCategoryForum} from "@/api/forum.api";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      imageUrl: '',
      thumbnail: null,
      loading: false,
    }
  },
  mounted() {
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          this.$nprogress.start();
          let formData = new FormData();
          formData.set("name", value.name);
          formData.set("description", value.description);
          formData.set("parent_id", "");
          if (this.thumbnail != null) {
            formData.set("icon", this.thumbnail);
          }
          this.submitData(formData);
        }
      })
    },
    beforeUpload() {
      return false;
    },
    onFileChange(info) {
      if (this.checkImageUpload(info.file)) {
        getBase64(info.file, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
        this.thumbnail = info.file;
      }
    },
    checkImageUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
      var validFormat = true;
      if (!isJpgOrPng) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Format Foto tidak Valid"
        });
        validFormat = false;
      }
      const isLt2M = file.size / 1024 <= 20480;
      if (!isLt2M) {
        this.$notification["warning"]({
          message: "Invalid",
          description: "Size terlalu besar!"
        });
        validFormat = false;
      }
      return validFormat;
    },
    async submitData(params) {
      try {
        let response = await createCategoryForum(params);
        if (response) {
          this.$notification["success"]({
            message: "Sukses",
            description: "Berhasil menambahkan kategori topik baru"
          });
         await this.$router.push("/forum/category");
        }
      } catch (error) {
        /**/
      }
      this.$nprogress.done();
    },
  }
}
</script>

<style scoped>

</style>
